 .notification-container {
   background-color: #29ff65;
   top: 0;
   left: 0;
   right: 0;
   z-index: 1000;
   position: absolute;
   padding: 10px 0;
   cursor: default;
 }

 .notification-item {
   display: inline-block;
   padding: 0 40px;
   text-transform: uppercase;

 }

 .notification-dot {
   display: inline-block;
   width: 6px;
   height: 6px;
   background-color: #000;
   border-radius: 90%;
   margin: 0 10px;
   margin-bottom: 2px;
 }

 .notification-header {
   font-weight:650;
   margin: 0 5px;
   margin-bottom: 2px;
 }