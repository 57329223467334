/*.body {
	border-bottom: solid 34px #000;
	background: #f7f7f7 url("/img/login-bg.png") no-repeat left bottom / contain;
}*/
#login-wrapper {
	background: url("/src/img/login.svg") no-repeat right top / auto 100%;
	padding: 0 40px;
	max-width: 1900px;
	position: relative;
}

h3 {
	margin: 30px 0 20px;
	font-size: 24px;
	line-height: 29px;
}

ul li {
	line-height: 36px;
	/* color: rgba(51, 60, 78, 0.6); */
}

h1 {
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.password {
	margin-top: 30px;
}

.btn.btn-link {
	font-weight: 600;
	line-height: 19px;
	text-decoration: none;
	text-underline-position: auto;
	border-bottom: 2px solid var(--bs-yellow);
	border-radius: 0;
	display: inline-block;
}

hr {
	margin-top: 60px;
}

.btn.btn-primary {
	width: 100%;
	height: 50px;
	background: var(--bs-yellow);
	border-radius: 4px;
	font-weight: 600;
	line-height: 19px;
	text-align: center;
	border: 1px solid var(--bs-yellow);
	margin-top: 80px;
}

.card-login {
	max-width: 400px;
	margin-top: 100px;
	position: absolute;
	width: 100%;
	right: 13%;
	height: 73%;
	backdrop-filter: blur(0px);
	top: 0;
}

.card-signup {
	max-height: 500px;
	overflow-y: auto;
}

.form-control::placeholder {
	line-height: 19px;
	color: rgba(51, 60, 78, .5);
}

.form-control {
	line-height: 19px;
	color: rgba(51, 60, 78, 1);
}

.logo {
	margin-top: 40px;
}

@media screen and (max-width: 2560px) {
	.card-login {
		right: 15%;
	}
}

@media screen and (max-width: 1920px) {
	.card-login {
		right: 13%;
	}

	#login-wrapper {
		max-width: 1900px;
	}
}

@media screen and (max-width: 1919px) {
	.card-login {
		margin-top: 80px;
		right: 10%;
		height: 73%;
	}

	#login-wrapper {
		max-width: 1560px;
	}
}

@media screen and (max-width: 1440px) {
	.card-login {
		margin-top: 80px;
		right: 10%;
		height: 73%;
	}

	#login-wrapper {
		max-width: 1400px;
	}
}

@media screen and (max-width: 1366px) {
	.card-login {
		margin-top: 80px;
		right: 7%;
		height: 73%;
	}

	#login-wrapper {
		max-width: 1320px;
	}
}

.card-footer.text-center.bg-white.border-0.p-0 {
	margin-top: 20px;
}

.card-reset {
	height: auto;
}

@media screen and (max-width: 768px) {
	#login-wrapper {
		background: #fff;
		width: 100% !important;
		padding: 20px !important;
	}

	.card-login {
		position: initial !important;
		padding: 0px !important;
		margin: 0px !important;
		width: 100% !important;
		right: unset !important;
		height: auto !important;
		max-width: 100% !important;
	}

	.card-login .input-group input,
	.card-login .input-group span {
		border-radius: 0px !important;
	}

	.card-login .input-group .input-group-text {
		position: absolute !important;
		right: 0;
		height: 40px;
	}
}