.order-type {
	margin-top: 40px;
}

.card-address a {
	line-height: 24px;
}

.card.card-tips .card-header {
	background: linear-gradient(180deg, rgba(240, 180, 27, .25) 0%, rgba(240, 180, 27, 0) 100%);
	border-radius: 2px;
	font-weight: 600;
	line-height: 19px;
	padding: 17px 20px;
	border-bottom-color: #181A23;
}
.card.card-tips .card-header p {
	line-height: 24px;
}
.card.card-tips .card-body {
	padding: 20px;
}
.card.card-tips .card-body img {
	margin-top: 90px;
	margin-bottom: 10px;
}
.card.card-tips .card-body h4 {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}
.card.card-tips .card-body p {
	line-height: 24px;
	text-align: center;
}
.card.card-tips .card-body hr {
	margin: 30px 0;
}
.card.card-tips .card-body ul li {
	display: inline-flex;
	width: 49%;
	list-style: disc;
}
.card.card-tips .card-body ul {
	padding-left: 15px;
	list-style: disc;
}
.switch {
	margin: 0px 10px;
	top: 0px;
}
.create-order-switch {
	line-height: 19px;
	text-align: center;
}
.create-order-switch span {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}
a.btn-collapse {
	background: var(--bs-yellow);
	padding: 9px 7px;
	border-radius: 5px 5px 0 0;
	position: absolute;
	bottom: 0;
	right: 10px;
}
.order-tips .note {
	margin-top: 80px;
}
.input-group-text {
	background-color: #fff;
	border: 1px solid var(--bs-border-color);
	border-radius: 2px;
}
.item-value .form-control {
	border-width: 1px 0;
}
.item-tax .form-control {
	border-width: 1px 1px 1px 0;
}
.item-tax .input-group-text {
	border-left: 0;
	border-radius: 0;
	border-right: 0;
}
.item-tax:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	left: -10px;
	top: 39px;
	z-index: 5;
}
.input-group.input-group-dimensions .form-control {
	border-width: 1px 0px 1px 0px;
}
.input-group.input-group-dimensions .form-control:first-child {
	border-left-width: 1px;
}
.input-group.input-group-dimensions .input-group-text:last-child {
	border-right-width: 1px;
}
.input-group.input-group-dimensions .input-group-text {
	position: relative;
	border-width: 1px 0;
	line-height: 19px;
	text-align: center;
}
.input-group-text {
	position: relative;
	line-height: 19px;
	text-align: center;
}
.input-group.input-group-dimensions .input-group-text:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	left: 45px;
	top: 10px;
	z-index: 5;
}
.input-group.input-weight .input-group-text:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 10px;
	z-index: 5;
}
.input-group.input-group-dimensions .input-group-text:last-child:before {
	width: 0;
}
.input-weight .input-group-text {
	border-width: 1px 1px 1px 0;
}
.form-title span {
	font-style: italic;
	line-height: 19px;
}
.est-invoice {
	line-height: 19px;
	color: var(--bs-gray-light);
	margin: 20px 0;
}
.est-invoice span {
	font-size: 18px;
	line-height: 21px;
}
/*Merchant Onboarding Steps (Start)*/
.vcv-timeline {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 480px;
	margin: 0;
	padding: 0;
	list-style-type: none;
	line-height: 19px;
}
.vcv-timeline-item {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	padding: 0;
}
.vcv-timeline-item::before, .vcv-timeline-item::after {
	content: '';
}
.vcv-timeline-item::before {
	content: attr(data-step);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 40px;
	height: 40px;
	margin: 0 10px 0 0;
	border-radius: 0;
	line-height: 14px;
	letter-spacing: 0.01em;
	font-size: var(--bs-form-label);
	background: url("/src/img/item-details.svg") no-repeat center center / auto 40px;
}
.vcv-timeline-item::after {
	height: 1px;
	background: var(--bs-gray);
	width: 100%;
	margin: 0 10px;
}
.vcv-timeline-item:last-of-type {
	flex: 0 0 120px;
}
.vcv-timeline-item:last-of-type::after {
	display: none;
}
.vcv-timeline-item.vcv-step-done::before {
	border-color: #aace35;
	background: url("/src/img/details.svg") no-repeat center center / auto 40px;
}
.vcv-timeline-item.vcv-step-done::after {
	background: var(--bs-gray);
}
.vcv-timeline-item.vcv-step-active::before {
	border-color: #aace35;
	background: url("/src/img/active-step.svg") no-repeat center center / auto 40px;
}
.input-group.input-mobile .input-group-text:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	right: 0;
	top: 10px;
	z-index: 5;
}
.input-mobile .input-group-text {
	border-width: 1px 0 1px 1px;
	border-color: #ADB1BA;
	border-radius: 2px;
	background: #e9e9e9;
}
.vcv-timeline span {
	white-space: nowrap;
}

@media screen and (max-width: 768px) {
.vcv-timeline {
	margin-bottom: 50px;
}
.vcv-timeline-item {
	position: relative;
}
.vcv-timeline-item span {
	text-align: left;
	margin: 10px 0 0;
	position: absolute;
	top: 30px;
	width: 100%;
	left: 2px;
}
.vcv-timeline-item:last-of-type {
	flex: 0 0 auto;
	margin: 0 30px 0 0;
}
.vcv-timeline-item::before {
	margin: 0;
}
.vcv-timeline-item::after {
	flex: 0 1 auto;
}
.vcv-timeline {
	max-width: 280px;
}
.vcv-timeline span {
	white-space: normal;
}
}

/*Merchant Onboarding Steps (End)*/
@media (max-width: 767px) {
.order-seq hr {
	top: -3px;
	margin: 0 5px;
	max-width: 20px;
}
.order-seq img {
	width: 25px;
}
.create-order-switch {
	margin-bottom: 20px;
}
.form-check-inline {
	margin-right: 0px;
	margin-bottom: 10px;
	display: block;
}
.btn.btn-primary, .btn.btn-outline-primary {
	width: 140px;
	margin-top: 30px;
}
}