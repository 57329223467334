.card-order-stats a {
	color: #333C4E;
	text-decoration: none;
}
.card-order-stats a:hover {
    color: #F0B41B;
    text-decoration: none;
}
.card.card-order-stats h4 {
    font-size: 24px;
}
.btn.btn-link {
	text-decoration: none;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333C4E;
}
.btn.btn-link img {
	margin-right: 7px;
	vertical-align: middle;
}
.form-check-input[type=checkbox] {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	border-color: #929BA3;
}
.form-check-label {
	padding-left: 7px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #333C4E;
}
.form-check-label h4 {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	margin: 0;
	color: #333C4E;
}
.card {
	background-color: #fff;
}
.card .card-body {
	padding: 20px;
}
.card .card-body:hover {
	/*background: rgba(240, 180, 27, .05);*/
}

.delivery-location:first-child {
	margin-bottom: 18px;
}
.card-body {
	padding: 25px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #333C4E;
}
.delivery-location:first-child:before {
	content: "";
	width: 1px;
	height: 41px;
	background: rgb(0, 0, 0, .5);
	position: absolute;
	top: 27px;
	left: 18px;
}
.action-btn a {
	opacity: 0;
	visibility: hidden;
}
.card:hover .action-btn a {
	opacity: 1;
	visibility: visible;
}
.card h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

@media (max-width: 767px) {
.card.card-distribution {
	height: auto;
}
}

@media (max-width: 767px) {
.card.card-order-stats h4 {
    font-size: 20px;
}
.delivery-location:first-child:before {
	content: "";
	width: calc(100% - 70px);
	height: 1px;
	top: 14px;
	left: 36px;
}
.card-body {
	font-size: 14px;
	line-height: 22px;
}
.card ul li {
	display: inline-block;
	width: 49%;
	margin-bottom: 5px;
}
.card .action-btn a {
	opacity: 1;
	visibility: visible;
}
.btn.btn-link img {
	margin-right: 0;
}
.card .action-btn a {
	padding: 15px 15px 0;
}
.card .action-btn a:last-child {
	padding-right: 0;
}
}