.card.card-tips .card-header {
    background: linear-gradient(180deg, rgba(240, 180, 27, .25) 0%, rgba(240, 180, 27, 0) 100%);
    border-radius: 2px;
    font-weight: 600;
    line-height: 19px;
    padding: 17px 20px;
    border-bottom-color: #181A23;
}

.card.card-tips .card-header p {
    line-height: 24px;
}

a.btn-collapse {
    background: var(--bs-yellow);
    padding: 9px 7px;
    border-radius: 5px 5px 0 0;
    position: absolute;
    bottom: 0;
    right: 10px;
}

/*Merchant Onboarding Steps (Start)*/
.vcv-timeline {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 930px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 19px;
}

.vcv-timeline-item {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    padding: 0;
}

.vcv-timeline-item::before,
.vcv-timeline-item::after {
    content: '';
}

.vcv-timeline-item::before {
    content: attr(data-step);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 40px;
    height: 40px;
    margin: 0 10px 0 0;
    border-radius: 0;
    line-height: 14px;
    letter-spacing: 0.01em;
    font-size: var(--bs-form-label);
    background: url('/src/img/item-details.svg') no-repeat center center / auto 40px;
}

.vcv-timeline-item::after {
    height: 1px;
    background: var(--bs-gray);
    width: 100%;
    margin: 0 10px;
}

.vcv-timeline-item:last-of-type {
    flex: 0 0 120px;
}

.vcv-timeline-item:last-of-type::after {
    display: none;
}

.vcv-timeline-item.vcv-step-done::before {
    border-color: #aace35;
    background: url('/src/img/details.svg') no-repeat center center / auto 40px;
}

.vcv-timeline-item.vcv-step-done::after {
    background: var(--bs-gray);
}

.vcv-timeline-item.vcv-step-active::before {
    border-color: #aace35;
    background: url("/src/img/active-step.svg") no-repeat center center / auto 40px;
}

.input-group.input-mobile .input-group-text:before {
    content: "";
    background: #D0D1D9;
    width: 1px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 5;
}

.input-mobile .input-group-text {
    border-width: 1px 0 1px 1px;
    border-color: #ADB1BA;
    border-radius: 2px;
    background: #e9e9e9;
}

@media screen and (max-width: 768px) {
    .vcv-timeline {
        margin-bottom: 50px;
    }

    .vcv-timeline-item {
        position: relative;
    }

    .vcv-timeline-item span {
        text-align: left;
        margin: 10px 0 0;
        position: absolute;
        top: 30px;
        width: 100%;
        left: 2px;
    }

    .vcv-timeline-item:last-of-type {
        flex: 0 0 auto;
        margin: 0 30px 0 0;
    }

    .vcv-timeline-item::before {
        margin: 0;
    }

    .vcv-timeline-item::after {
        flex: 0 1 auto;
    }
}

/*Merchant Onboarding Steps (End)*/
.verify {
    font-weight: 600;
    line-height: 19px;
    color: rgba(51, 60, 78, .9);
    padding-top: 40px;
}

.ad-space {
    border-color: #000 !important;
    padding: 30px;
    min-height: 680px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.imageuploadify .imageuploadify-images-list .imageuploadify-container {
    width: 100%;
    height: 100px;
    margin-bottom: 0;
    float: left;
    border-radius: 0;
    box-shadow: none;
}

.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details {
    display: none;
}

.imageuploadify .imageuploadify-images-list {
    width: 100%;
}

.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
    height: auto;
    left: auto;
    position: relative;
    top: auto;
    transform: inherit;
    width: auto;
}

.imageuploadify .btn.btn-remove img {
    width: 22px;
}

.imageuploadify .btn.btn-remove,
.btn.btn-remove:focus {
    position: absolute;
    right: 0;
    outline: 0;
    border: 0;
    font-weight: 600;
}

.btn-cancel {
    font-weight: 600;
    line-height: 19px;
    border-bottom: 1.5px solid var(--bs-yellow);
    margin-right: 50px;
    margin-top: 80px;
    display: inline-block;
}

#add-location {
    height: calc(100% - 100px);
}

@media (max-width: 767px) {

    .btn.btn-primary,
    .btn.btn-outline-primary {
        width: 140px;
        margin-top: 30px;
    }

    #add-location {
        height: auto;
    }
}

.link-top {
    margin-top: 40px;
}

.no-border {
    text-decoration: none;
    border-bottom: none !important;
}