.ad-space {
	border-color: #000 !important;
	padding: 30px;
	min-height: 680px;
	justify-content: center;
	align-items: center;
	display: flex;
}
.card-company-profile .card-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}
.company-logo {
	width: 110px;
	height: 110px;
	left: 145px;
	top: 183px;
	border: solid 2px rgba(17,187,17,.15);
	border-radius: 4px;
	padding: 10px;
}
.download-template {
	line-height: 19px;
}
.download-template a {
	font-weight: 600;
	text-decoration: none;
	border-bottom: solid 1px var(--bs-yellow);
	margin-top: 5px;
	display: inline-block;
}
.download-template a:hover {
	color: var(--bs-yellow);
}

@media (max-width: 767px) {
}