.card-address a {
	line-height: 24px;
}
.card.card-tips .card-header {
	background: linear-gradient(180deg, rgba(240, 180, 27, .25) 0%, rgba(240, 180, 27, 0) 100%);
	border-radius: 2px;
	font-weight: 600;
	line-height: 19px;
	padding: 17px 20px;
	border-bottom-color: #181A23;
}
.card.card-tips .card-header p {
	line-height: 24px;
}
a.btn-collapse {
	background: var(--bs-yellow);
	padding: 9px 7px;
	border-radius: 5px 5px 0 0;
	position: absolute;
	bottom: 0;
	right: 10px;
}
/*Merchant Onboarding Steps (Start)*/
.vcv-timeline {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 930px;
	margin: 0;
	padding: 0;
	list-style-type: none;
	line-height: 19px;
}
.vcv-timeline-item {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	padding: 0;
}
.vcv-timeline-item::before, .vcv-timeline-item::after {
	content: '';
}
.vcv-timeline-item::before {
	content: attr(data-step);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 40px;
	height: 40px;
	margin: 0 10px 0 0;
	border-radius: 0;
	line-height: 14px;
	letter-spacing: 0.01em;
	font-size: 12px;
	background: url('/src/img/item-details.svg') no-repeat center center / auto 40px;
	color: #333C4E;
}
.vcv-timeline-item::after {
	height: 1px;
	background: var(--bs-gray);
	width: 100%;
	margin: 0 10px;
}
.vcv-timeline-item:last-of-type {
	flex: 0 0 120px;
}
.vcv-timeline-item:last-of-type::after {
	display: none;
}
.vcv-timeline-item.vcv-step-done::before {
	border-color: #aace35;
	background: url('/src/img/details.svg') no-repeat center center / auto 40px;
}
.vcv-timeline-item.vcv-step-done::after {
	background: var(--bs-gray);
}
.input-group.input-mobile .input-group-text:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	right: 0;
	top: 10px;
	z-index: 5;
}
.input-mobile .input-group-text {
	border-width: 1px 0 1px 1px;
	border-color: #ADB1BA;
	border-radius: 2px;
	background: #e9e9e9;
}

@media screen and (max-width: 768px) {
.vcv-timeline {
	margin-bottom: 50px;
}
.vcv-timeline-item {
	position: relative;
}
.vcv-timeline-item span {
	text-align: left;
	margin: 10px 0 0;
	position: absolute;
	top: 30px;
	width: 100%;
	left: 2px;
}
.vcv-timeline-item:last-of-type {
	flex: 0 0 auto;
	margin: 0 30px 0 0;
}
.vcv-timeline-item::before {
	margin: 0;
}
.vcv-timeline-item::after {
	flex: 0 1 auto;
}
}
/*Merchant Onboarding Steps (End)*/	
.verify {
	font-weight: 600;
	line-height: 19px;
	color: rgba(51,60,78,.9);
	padding-top: 40px;
}
.ad-space {
	border-color: #000 !important;
	padding: 30px;
	min-height: 890px;
	justify-content: center;
	align-items: center;
	display: flex;
	background: linear-gradient(180deg, rgba(240, 180, 27, .25) 0%, rgba(240, 180, 27, 0) 100%);
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container {
	width: 100%;
	height: auto;
	margin-bottom: 0;
	float: left;
	border-radius: 0;
	box-shadow: none;
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details {
	display: none;
}
.imageuploadify .imageuploadify-images-list {
	width: 100%;
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
	height: auto;
	left: auto;
	position: relative;
	top: auto;
	transform: inherit;
	width: auto;
	max-width: 240px;
}
.imageuploadify .btn.btn-remove img {
	width: 22px;
}
.imageuploadify .btn.btn-remove, .btn.btn-remove:focus {
	position: absolute;
	right: 0;
	outline: 0;
	border: 0;
	font-weight: 600;
}
.verify {
	font-weight: 600;
	line-height: 19px;
	color: rgba(51,60,78,.9);
	padding-top: 40px;
}

@media (max-width: 767px) {
.form-check-inline {
	margin-right: 0px;
	margin-bottom: 10px;
	display: block;
}
.btn.btn-primary, .btn.btn-outline-primary {
	width: 140px;
	margin-top: 30px;
}
.verify {
	padding-top: 0px;
}
}
.imageuploadify {
    border: 2px dashed var(--bs-yellow);
    position: relative;
    min-height: 150px;
    min-width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 20px;
    flex-direction: column;
    text-align: center;
    background-color: rgba(240, 180, 27, 0.1);
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageuploadify .imageuploadify-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  display: none;
  font-size: 7em;
  background-color: rgba(242, 242, 242, .7);
  text-align: center;
  pointer-events: none
}
.imageuploadify .imageuploadify-overlay i {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none
}
.imageuploadify .imageuploadify-images-list {
  display: inline-block
}
.imageuploadify .imageuploadify-images-list i {
  display: block;
  font-size: 7em;
  text-align: center;
  margin-top: .5em;
  padding-bottom: 12px
}
.imageuploadify .imageuploadify-images-list span.imageuploadify-message {
  
  
  
  line-height: 24px;
  text-align: center;
  
  display: block;
}
button.btn.btn-browse {
  
  font-weight: 600;
  
  line-height: 19px;
  color: var(--bs-gray) !important;
  background-color: transparent !important;
	border:0;
}
button.btn.btn-browse img {
    margin-right: 10px;
    vertical-align: middle;
}
.imageuploadify .imageuploadify-images-list button.btn-default {
  display: block;
  color: #3AA0FF;
  border-color: #3AA0FF;
  border-radius: 1em;
  margin: 25px auto;
  width: 100%;
  max-width: 500px
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;
  float: left;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #888
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container button.btn-danger {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  font-size: 10px;
  line-height: 1.42;
  padding: 2px 0;
  text-align: center;
  z-index: 3
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container img {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details {
  position: absolute;
  top: 0;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgba(255, 255, 255, .5);
  z-index: 2;
  opacity: 0
}
.imageuploadify .imageuploadify-images-list .imageuploadify-container .imageuploadify-details span {
  display: block
}
.onboarding-btn{
	width: 184px !important;
}
.merchant-step-link{
	text-decoration: none;
    color: rgba(51,60,78,0.6);
}