.border-bottom {
    border-bottom-color: var(--bs-border-color-table) !important;
}
.card.card-ndr-list li {
	margin: 4px 0;
}
.tab-content {
	padding-top: 20px;
	padding-bottom: 20px;
}
.MuiDataGrid-root .MuiDataGrid-cell{
	white-space: normal !important;
	word-wrap: break-word !important;
	}
.btn-view-all {
	font-weight: 600;
	line-height: 19px;
	border-bottom: 2px solid var(--bs-yellow);
	text-decoration: none;
}
.track-order .btn-dark {
	right: 0;
}
.nav-tabs {
	border-color: #181A23;
}
.nav-tabs .nav-link {
	line-height: 19px;
	color: #181A23;
	opacity: 0.9;
	padding-top: 9px;
	padding-bottom: 9px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	background: var(--bs-yellow);
	font-weight: 600;
	line-height: 19px;
	color: #181A23;
	border-top: solid 1px #181A23;
	border-left: solid 1px #181A23;
	border-right: solid 1px #181A23;
}
.btn.btn-link.btn-upload {
	text-decoration: none;
	font-weight: 600;
	line-height: 19px;
}
.card.card-distribution {
	height: calc(100% - 0px);
}
.card-order-stats a {
	text-decoration: none;
}
.card-order-stats a:hover {
	color: var(--bs-yellow);
	text-decoration: none;
}
.card-form-check label {
font-weight: 500;
font-size: 18px;
line-height: 22px;
}
.delivery-location:first-child {
	margin-bottom: 10px;
}
.header-row {
	font-weight: 600;
	color: var(--bs-gray-dark);
}
hr {
	opacity: 1;
	border-color: #1B1B1B;
}

.card .card-body.header-row:hover {
	background: transparent;
}
#NDRTabContent .card .card-body.header-row {
	padding: 18px 25px;
}
.card-ndr-list .btn.btn-link {
	border-bottom: solid 1px #f0ba30;
	border-radius: 0;
}
.card-ndr-list .action-btn a.btn.btn-link {
	opacity: 0;
	visibility: hidden;
	border: 0;
}
.card-ndr-list:hover .action-btn a.btn.btn-link {
	opacity: 1;
	visibility: visible;
}
.col-awb {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}
.col-status {
	color: var(--bs-gray-light);
}
.card ul li:nth-child(even) {
	display: inline-flex;
	justify-content: flex-end;
}
.popover {
	background-color: #FFEDAD;
	box-shadow: 0px 4px 54px rgb(0 0 0 / 9%);
	border: solid 1px #FFCC67;
	border-radius: 2px;
	backdrop-filter: blur(12px);
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, .bs-popover-start>.popover-arrow::before {
	border-left-color: #FFCC67;
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, .bs-popover-start>.popover-arrow::after {
	border-left-color: #FFEDAD;
}
.popover-body {
	line-height: 19px;
}
.popover-body h4 {
	font-weight: 600;
	margin-bottom: 8px;
	line-height: 19px;
	font-size: var(--bs-body-font-size);
}
.popover-body hr {
	border-color: #FCC927;
}
.input-group-text {
	border-width: 1px 0 1px 1px;
	border-color: #ADB1BA;
	border-radius: 2px;
	background: transparent;
}
.input-group .input-group-text:before {
	content: "";
	background: #D0D1D9;
	width: 1px;
	height: 18px;
	position: absolute;
	left: 40px;
	top: 10px;
	z-index: 5;
}
.address p {
	font-weight: 600;
	line-height: 19px;
	color: rgba(51,60,78,.9);
}

@media (max-width: 950px) {
.card-order-stats .table td {
	white-space: nowrap;
}
.card.card-distribution {
	height: calc(100% - 0px);
}
}

@media (max-width: 767px) {
.card.card-order-stats h4 {
	font-size: 20px;
}
.delivery-location:first-child:before {
	content: "";
	width: calc(100% - 70px);
	height: 1px;
	top: 14px;
	left: 36px;
}
.card-body {
	font-size: 14px;
	line-height: 22px;
}
.card ul li {
	display: inline-block;
	width: 49%;
	margin-bottom: 5px;
}
.card .action-btn a {
	opacity: 1;
	visibility: visible;
}
.btn.btn-link img {
	margin-right: 0;
}
.card .action-btn a {
	padding: 15px 15px 0;
}
.card .action-btn a:last-child {
	padding-right: 0;
}
}
.ndr-label{
    font-weight: 500  !important;
    font-size: inherit !important;
    letter-spacing: normal !important;
}