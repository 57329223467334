.card-order-stats .table td h4 {
	font-size: 24px;
	line-height: 29px;
}
.tab-content {
	padding-top: 30px;
	padding-bottom: 30px;
}
.btn-view-all {
	color: var(--bs-gray);
	font-weight: 600;
	line-height: 19px;
	border-bottom: 2px solid var(--bs-yellow);
	text-decoration: none;
}
.track-order .btn-dark {
	right: 0;
}
.nav-tabs {
	border-color: #181A23;
}
.nav-tabs .nav-link {
	line-height: 19px;
	color: #181A23;
	opacity: 0.9;
	padding-top: 9px;
	padding-bottom: 9px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	background: var(--bs-yellow);
	font-weight: 600;
	line-height: 19px;
	color: #181A23;
	border-top: solid 1px #181A23;
	border-left: solid 1px #181A23;
	border-right: solid 1px #181A23;
}
.filter-date {
	line-height: 19px;
	text-align: left;
	color: rgba(51,60,78,.70);
	position: absolute;
	top: 0;
	right: 0px;
}
.filter-date .form-select {
	width: 140px;
	display: inline-block;
	line-height: 19px;
	padding-left: 5px;
}
.btn.btn-link.btn-upload {
	text-decoration: none;
	font-weight: 600;
	line-height: 19px;
}
.card.card-distribution {
	height: calc(100% - 0px);
}
.table td {
	padding-top: 12px;
	padding-bottom: 12px;
	color: var(--bs-gray-medium);
	vertical-align: middle;
	border-color: var(--bs-border-color-table);
}
.table tbody tr:hover {
	background: rgba(240, 180, 27, .05);
}
.table th {
	font-weight: 600;
	line-height: 19px;
	color: #878E9E;
}
thead tr {
	border-color: var(--bs-border-color-dark-table);
}
.card-order-stats a, .card-ndr a, .card-courier a {
	text-decoration: none;
}
.card-order-stats a:hover, .card-ndr a:hover, .card-courier a:hover {
	color: var(--bs-yellow);
	text-decoration: none;
}
.select2-container--default .select2-selection--single {
	border: 0px solid var(--bs-border-color);
	border-radius: 2px;
	height: auto;
	background: transparent;
}
/*.select2-container {
	width: auto !important;
}*/
.select2-container--default .select2-selection--single .select2-selection__placeholder {
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 5px;
	padding-right: 30px;
}
.border-secondary {
	border-color: rgba(51,60,78,.5) !important;
}
.btn.btn-link {
	text-decoration: none;
	font-weight: 600;
	line-height: 19px;
}
.action-btn a {
	opacity: 0;
}
.table tbody tr:hover .action-btn a {
	opacity: 1;
}
#ndr-tab-pane .card {
	height: calc(100% - 20px);
}
.select2-container--default .select2-results__option--selected {
	background-size: 15px;
}
.select2-results__option {
	font-size: 14px;
	padding: 10px 22px 10px 10px;
}
#courier-tab-pane .card .card-title+img {
	margin-top: 20px;
	margin-bottom: 20px;
}

@media (max-width: 950px) {
.card-order-stats .table td {
	white-space: nowrap;
}
.card.card-distribution {
	height: calc(100% - 0px);
}
.card-ndr .border-start.border-secondary, .card-courier .border-start.border-secondary {
	border: 0 none !important;
}
}

@media (max-width: 767.98px) {
.card.card-distribution {
	height: auto;
}
.card-order-stats .table td {
	white-space: nowrap;
}
.filter-date {
	font-size: 15px;
	top: 176px;
	right: 5px;
}
#ndr-tab-pane .card {
	height: auto;
}
.table tbody tr .action-btn a {
	opacity: 1;
}
td.action-btn {
	white-space: nowrap;
}
.card-ndr .border-start.border-secondary, .card-courier .border-start.border-secondary {
	border: 0 none !important;
}
}
.custom-tooltip{
	background-color: white;
    box-shadow: 0px 0px 14px rgb(0 0 0 / 30%);
	padding: 5px 10px;
}
/* .node-foreign-object-div {
	background-image: linear-gradient(to right, #ffffff, #ebe4d6);
	padding: 10px 10px;
	font-family: Roboto, sans-serif;
	font-size: 12px;
	border-radius: 5px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgb(145 158 171 / 52%);
  }
  
  .node-foreign-object-div p {
	margin-top: 5px;
	font-size: 13px;
  }
  
  .node-button-div > div {
	border-radius: 50% !important;
	font-size: 12px !important;
	height: 20px;
	width: 20px;
	text-align: center;
	background-color: rgb(171, 173, 176) !important;
	color: #fff !important;
	display: flex;
	align-items: center;
	justify-content: center;
  } */