  .title {
    background-color: #404e7c;
    color: #fef9ef;
    text-align: center;
    padding: 1rem;
    font-size: 1.5em;
    z-index: 1;
  }

  /* orgchart css */
  .org-chart {
    height: calc(100vh - 60px);
  }

  .orgChart svg {
    width: 100%;
  }

  .org-chart>svg {
    height: 100%;
    background-color: #eaeaea;
  }

  .node-container {
    min-height: 120px;
    color: #227c9d;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    background-image: linear-gradient(#ededed, #ffffff);
  }

  .node-details {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .node-content {
    display: flex;
    align-items: center;
  }

  .node-team {
    width: 100%;
    text-align: center;
  }

  .node-team-name {
    margin-bottom: 0.5rem;
    color: #fef9ef;
    font-size: 1.5rem;
  }


  .node-info {
    color: #fef9ef;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
  }

  .node-name {
    font-size: 22px;
    font-weight: 500;
    color: #000000cf;
    font-family: "Poppins", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .node-role {
    font-size: 27px;
    font-weight: bold;
    color: #000000cf;
    margin-top: 5px;
    padding-bottom: 5px;
  }

  .node-department {
    padding: 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: #ededed;
    border-radius: 1rem;
    color: #227c9d;
  }

  .node-department>svg {
    margin-right: 0.5rem;
  }

  .expand-btn {
    width: 50px;
    height: 50px;
    margin: auto;
    color: #000000cf;
    background-image: linear-gradient(#ededed, #ffffff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    flex-direction: column;
  }

  .expand-btn span {
    display: flex;
    font-size: 20px;
    font-weight: bold;
  }
  .expand-btn span svg{
    stroke: #000000cf
  }
  .link{
    stroke-width:1;
    stroke: #212b36b3;
  }
  .node-button-foreign-object{
    width:50px;
    height:50px;
  }