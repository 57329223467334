.card-wallet{
    border-radius: 10px;
}
.balance-row{
    min-height: 200px;
 } 
 
 .balance{
    border-right: 1px solid #181A23;
 }
 .btn.btn-primary.amt-btn{
    width: 18%;
    margin-right: 2px;
    margin-top: auto;
 }